<!-- # THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. -->
<!-- # Generated by scripts/import-svg-icons.js -->
<template>
  <svg-icon
    :data="svg[$props.name]"
    :width="$props.width"
    :height="$props.height"
    original
  ></svg-icon>
</template>
<script>
import { defineComponent } from 'vue'
import icLv0 from '@/assets/icons/ui/ic.lv0.svg'
import icLv1 from '@/assets/icons/ui/ic.lv1.svg'
import icLv2 from '@/assets/icons/ui/ic.lv2.svg'
import icLv3 from '@/assets/icons/ui/ic.lv3.svg'
import icLv4 from '@/assets/icons/ui/ic.lv4.svg'
import icLv5 from '@/assets/icons/ui/ic.lv5.svg'
import icLv6 from '@/assets/icons/ui/ic.lv6.svg'
import icSakura from '@/assets/icons/ui/ic.sakura.svg'
import socialBilibili from '@/assets/icons/ui/social.bilibili.svg'
import socialDouban from '@/assets/icons/ui/social.douban.svg'
import socialFacebook from '@/assets/icons/ui/social.facebook.svg'
import socialGithub from '@/assets/icons/ui/social.github.svg'
import socialGitlab from '@/assets/icons/ui/social.gitlab.svg'
import socialInstagram from '@/assets/icons/ui/social.instagram.svg'
import socialMastodon from '@/assets/icons/ui/social.mastodon.svg'
import socialPixiv from '@/assets/icons/ui/social.pixiv.svg'
import socialQq from '@/assets/icons/ui/social.qq.svg'
import socialQzone from '@/assets/icons/ui/social.qzone.svg'
import socialStackoverflow from '@/assets/icons/ui/social.stackoverflow.svg'
import socialTelegram from '@/assets/icons/ui/social.telegram.svg'
import socialTwitch from '@/assets/icons/ui/social.twitch.svg'
import socialTwitter from '@/assets/icons/ui/social.twitter.svg'
import socialWeibo from '@/assets/icons/ui/social.weibo.svg'
import socialWeichat from '@/assets/icons/ui/social.weichat.svg'
import socialYoutube from '@/assets/icons/ui/social.youtube.svg'
import socialZhihu from '@/assets/icons/ui/social.zhihu.svg'

export default defineComponent({
  name: 'Icon',
  props: {
    name: { type: String },
    width: { type: String, default: '100%' },
    height: { type: String, default: '100%' },
  },
  setup() {
    const svg = {
      'ic.lv0': icLv0,
      'ic.lv1': icLv1,
      'ic.lv2': icLv2,
      'ic.lv3': icLv3,
      'ic.lv4': icLv4,
      'ic.lv5': icLv5,
      'ic.lv6': icLv6,
      'ic.sakura': icSakura,
      'social.bilibili': socialBilibili,
      'social.douban': socialDouban,
      'social.facebook': socialFacebook,
      'social.github': socialGithub,
      'social.gitlab': socialGitlab,
      'social.instagram': socialInstagram,
      'social.mastodon': socialMastodon,
      'social.pixiv': socialPixiv,
      'social.qq': socialQq,
      'social.qzone': socialQzone,
      'social.stackoverflow': socialStackoverflow,
      'social.telegram': socialTelegram,
      'social.twitch': socialTwitch,
      'social.twitter': socialTwitter,
      'social.weibo': socialWeibo,
      'social.weichat': socialWeichat,
      'social.youtube': socialYoutube,
      'social.zhihu': socialZhihu,
    }
    return {
      svg,
    }
  },
})
</script>
